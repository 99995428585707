import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@material-ui/icons/Room";
import ReactHtmlParser from "react-html-parser";
// import MarkerClusterer from "@googlemaps/markerclustererplus";

// import Marker from "./Marker";
import Pagination from "../Pagination";
import styles from "./styles";
import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock";
import ProductSignUp from "../Product/ProductSignUp";
import { setPage } from "../../app/actions";
import pin from "../../images/map-pin.png";

const mapStateToProps = (state) => ({
  page: state.ShopSettings.page,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setPage(page)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function SearchPage({ page, locations, factors, prefill, setPage }) {
  const [name, setName] = useState(prefill ? prefill.name : "");
  const [postcode, setPostcode] = useState(prefill ? prefill.postcode : "");
  const [radius, setRadius] = useState(prefill ? prefill.radius : "");
  const [factor, setFactor] = useState(prefill ? prefill.factor : "");
  const [stockists, setStockists] = useState([]);
  const [filteredStockists, setFilteredStockists] = useState([]);
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState("");
  const [letter, setLetter] = useState("all");
  const [hoverIndex, setHoverIndex] = useState(false);

  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const formRef = useRef(null);

  useEffect(() => {
    if (!stockists.length && !name && !postcode && !radius && !searching) {
      setStockists(preloadData());
    }

    const script = document.createElement("script");
    script.src =
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
    script.async = true;
    document.body.appendChild(script);
  }, [stockists]); //eslint-disable-line

  useEffect(() => {
    if (!prefill) {
      return null;
    }

    if (prefill.name || prefill.postcode) {
      fetchStockists();
    }
  }, [prefill]); //eslint-disable-line

  useEffect(() => {
    setFilteredStockists(
      stockists.filter((stockist) => {
        if (letter === "all") return stockist;

        const firstLetter = stockist.title.split("")[0];

        if (letter === "0-9") {
          return Number.isInteger(Number(firstLetter));
        }

        return firstLetter === letter;
      })
    );
    setHoverIndex(false);
  }, [stockists, letter]);

  function googleMapRef(map, maps) {
    // const googleMapRef = map;
    const googleRef = maps;

    const image = new googleRef.MarkerImage(pin, new googleRef.Size(24, 32));

    const bounds = new googleRef.LatLngBounds();

    let markers =
      filteredStockists &&
      filteredStockists.map((location) => {
        const newPoint = new googleRef.LatLng(
          Number(location.latitude),
          Number(location.longitude)
        );
        bounds.extend(newPoint);
        return new googleRef.Marker({
          position: {
            lat: Number(location.latitude),
            lng: Number(location.longitude),
          },
          icon: image,
        });
      });

    // let markerCluster = new MarkerClusterer(map, markers, {
    //   imagePath:
    //     "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    //   gridSize: 75,
    //   minimumClusterSize: 3,
    // });

    markers.forEach((marker, index) => {
      googleRef.event.addListener(marker, "mouseover", (e) => {
        setHoverIndex(index);
      });
    });

    // const newBounds = {
    //   ne: {
    //     lat: bounds.getNorthEast().lat(),
    //     lng: bounds.getNorthEast().lng(),
    //   },
    //   sw: {
    //     lat: bounds.getSouthWest().lat(),
    //     lng: bounds.getSouthWest().lng(),
    //   },
    // };

    map.fitBounds(bounds);
  }

  function preloadData() {
    return locations.nodes.map((location) => {
      const { uri, title } = location;
      const {
        address1,
        address2,
        address3,
        address4,
        city,
        country,
        email,
        postcode,
        telephone,
        latitude,
        longitude,
        stockist,
      } = location.LocationInformation;
      return {
        uri,
        title,
        address_1: address1,
        address_2: address2,
        address_3: address3,
        address_4: address4,
        city,
        country,
        email,
        postcode,
        telephone,
        latitude,
        longitude,
        stockist: stockist.slug,
      };
    });
  }

  function fetchStockists() {
    axios
      .get(
        `${
          process.env.GATSBY_ADMIN_URL
        }/wp-json/brew/v1/search-locations?location=${postcode}&radius=${
          radius ? radius : 20
        }&limit=500`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data.data.locations)
      .then((locations) => {
        return locations
          .filter((stockist) => {
            if (!name) return stockist;

            return stockist.title
              .toLocaleLowerCase()
              .includes(name.toLocaleLowerCase());
          })
          .filter((stockist) => {
            if (factor) {
              return stockist.stockist.toLowerCase() === factor.toLowerCase();
            }

            return stockist;
          });
      })
      .then((locations) => {
        setStockists(locations);
      })
      .then(() => {
        setSearching(false);
      });
  }

  function handleSubmit(e) {
    if (e) e.preventDefault();

    setPage(1);

    if (!name && !postcode && !radius && !factor) {
      return setStockists(preloadData());
    }

    if (name && !postcode && !radius) {
      const newStockists = preloadData().filter((stockist) => {
        if (!stockist.title.toLowerCase().includes(name.toLowerCase()))
          return false;

        return stockist;
      });
      setStockists(newStockists);
    } else {
      setStockists([]);

      setSearching(true);
      setQuery(postcode);

      fetchStockists();
    }
  }

  function renderError() {
    return (
      <>
        <p>Your search hasn’t matched any of our available FAQs.</p>
        <p>Looking for information on something specific?</p>
        <p>
          Get in touch with the experts at Workshop Pro today via the{" "}
          <Link to="/contact-us/">contact page</Link>.
        </p>
      </>
    );
  }

  function handleLetterFilter(e) {
    setLetter(e.target.value);
    setPage(1);
  }

  function handleReset(e) {
    if (e) e.preventDefault();
    setName("");
    setPostcode("");
    setRadius("");
    setFactor("");
    setStockists([]);
    setSearching(false);

    if (formRef.current) {
      formRef.current.reset();
    }
  }

  const pageToShow =
    filteredStockists.length > 0 &&
    filteredStockists.slice(page * 10 - 10, page * 10);
  const stockist = hoverIndex !== false && filteredStockists[hoverIndex];

  return (
    <div className={classes.searchPage}>
      {filteredStockists.length > 0 && !searching && (
        <div
          style={{
            height: "400px",
            width: "100vw",
            marginLeft: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <GoogleMapReact
            key={filteredStockists.length}
            bootstrapURLKeys={{ key: process.env.GATSBY_GMAPS_KEY }}
            defaultCenter={{
              lat: 52.551371681561236,
              lng: -1.9362607165406438,
            }}
            onGoogleApiLoaded={({ map, maps }) => googleMapRef(map, maps)}
            defaultZoom={query ? 10 : 6}
            yesIWantToUseGoogleMapApiInternals
            className="map"
          >
            {!!stockist && (
              <div className="info">
                <button onClick={() => setHoverIndex(false)}>x</button>
                <div className="location">
                  <h3>{stockist.title}</h3>
                  <p>
                    <span>{stockist.address_1}, </span>
                    <span>{stockist.address_2}, </span>
                    <span>{stockist.city}, </span>
                    <span>{stockist.country}, </span>
                    <span>{stockist.postcode}</span>
                  </p>
                </div>
                <div className="action">
                  {stockist.uri && (
                    <Link
                      to={`${
                        stockist.uri.includes("locations") ? "" : "/locations/"
                      }${stockist.uri}`}
                    >
                      Find out more
                    </Link>
                  )}
                </div>
              </div>
            )}
          </GoogleMapReact>
        </div>
      )}

      {searching && <div className="searching">Searching...</div>}

      <div className={classes.stockistForm}>
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Find a distributor",
            level: 2,
            textColor: "foreground_primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="name">
            <label htmlFor="name">Name</label>
            <input
              name="name"
              placeholder="Name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="postcode">
            <label htmlFor="postcode">Postcode</label>
            <input
              type="postcode"
              defaultValue={postcode}
              onChange={(e) => setPostcode(e.target.value)}
            />
          </div>
          <div className="radius">
            <label htmlFor="radius">Radius</label>
            <select
              name="radius"
              defaultValue={radius}
              onChange={(e) => setRadius(e.target.value)}
            >
              <option disabled value="">
                --
              </option>
              <option value={20}>20 miles</option>
              <option value={50}>50 miles</option>
              <option value={100}>100 miles</option>
            </select>
          </div>
          <div className="factor-type">
            <label htmlFor="factor-type">Factor Type</label>
            <select
              name="factor-type"
              defaultValue={factor}
              onChange={(e) => setFactor(e.target.value)}
            >
              <option disabled value="">
                --
              </option>
              {factors.edges.map((factor) => {
                const { slug, title } = factor.node;
                return (
                  <option key={slug} value={title}>
                    {title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="actions">
            <input type="submit" value="search" />
            <button type="button" onClick={(e) => handleReset(e)}>
              Reset
            </button>
          </div>
        </form>
      </div>

      {query && !searching && !stockists.length && renderError()}

      <div className="search-filter">
        <form //eslint-disable-line
          className={classes.letters}
          onBlur={handleLetterFilter}
        >
          <p>A-Z:</p>
          {[
            "0-9",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "all",
          ].map((l) => {
            const stockistsByLetter = stockists.filter((stockist) => {
              if (l === "all") return true;

              const firstLetter = stockist.title.split("")[0];

              if (l === "0-9") {
                return Number.isInteger(Number(firstLetter));
              }

              return firstLetter === l;
            });

            return (
              <span key={l}>
                <label
                  className={`${l === letter ? "active" : ""} ${
                    stockistsByLetter.length ? "" : "disabled"
                  }`}
                >
                  {l}
                </label>
                <input
                  type="radio"
                  name="letter"
                  disabled={!stockistsByLetter.length}
                  value={l}
                />
              </span>
            );
          })}
        </form>
      </div>

      <div className={classes.searchResults}>
        {pageToShow.length > 0 &&
          pageToShow.map((stockist, index) => {
            return (
              <div className="result-item" key={index}>
                <div className="location">
                  <h3>{ReactHtmlParser(stockist.title)}</h3>
                  <p>
                    <span>{stockist.address_1}, </span>
                    <span>{stockist.address_2}, </span>
                    <span>{stockist.city}, </span>
                    <span>{stockist.country}, </span>
                    <span>{stockist.postcode}</span>
                  </p>
                </div>
                <div className="distance">
                  {stockist.distance !== undefined && (
                    <p>
                      <RoomIcon /> {stockist.distance} miles
                    </p>
                  )}
                </div>
                <div className="action">
                  {stockist.uri && (
                    <Link
                      to={`${
                        stockist.uri.includes("locations") ? "" : "/locations/"
                      }${stockist.uri}`}
                    >
                      Find out more
                    </Link>
                  )}
                </div>
              </div>
            );
          })}

        <Pagination
          products={filteredStockists}
          num={10}
          scrollTarget={".search-filter"}
        />
      </div>

      <ProductSignUp />
    </div>
  );
}

export default connector(SearchPage);
