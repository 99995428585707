import chevron from "../../images/chevron.png";
import search from "../../images/search-solid.png";
import close from "../../images/active-brand.png";

export default theme => ({
    stockistForm: {
        position: 'relative',
        padding: '90px 0 ',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            marginBottom: 80,
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100vw',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            display: 'block',
            backgroundColor: theme.palette.primary.main,
            zIndex: -1,
        },
        '& form': {
            marginTop: 30,
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                margin: '0 -12px',
                width: 'calc(100% + 24px)',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'flex-end',
                '& .name': {
                    flex: '0 0 40%',
                    padding: '0 12px',
                },
                '& .postcode, & .radius, & .factor-type': {
                    flex: '0 0 20%',
                    padding: '0 12px',
                },
                '& .actions': {
                    flex: '0 100%',
                    padding: '0 12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& input, & button': {
                        flex: '0 0 calc(50% - 12px)',
                    }
                }
            },
            [theme.breakpoints.up('lg')]: {
                '& .name': {
                    flex: '0 0 29.1%',
                },
                '& .postcode': {
                    flex: '0 0 12.5%',
                },
                '& .factor-type': {
                    flex: '0 0 16.666%',
                },
                '& .radius': {
                    flex: '0 0 16.666%',
                },
                '& .actions': {
                    flex: '0 0 25%',
                    '& input': {
                        flex: '0 0 calc(50% - 12px)',
                    },
                    '& button': {
                        flex: '0 0 calc(50% - 12px)',
                    }
                }
            },
            '& > div': {
                '& label': {
                    color: theme.palette.primary.contrastText,
                    fontSize: 12,
                    lineHeight: 1.6,
                    letterSpacing: '0.0152em',
                    textTransform: 'uppercase',
                    margin: 0,
                    fontFamily: theme.bodyFont,
                },
                '& input, & textarea, & select, & button': {
                    fontFamily: theme.bodyFont + ', sans-serif',
                    borderRadius: 20,
                    fontSize: 12,
                    lineHeight: 2,
                    padding: '7px 20px',
                    border: '1px solid ' + theme.palette.primary.contrastText,
                    marginBottom: 24,
                    outline: 'none',
                    display: 'block',
                    width: '100%',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.16)',
                    '-moz-appearance': 'none',
                    '-webkit-appearance': 'none',
                },
                '& select': {
                    backgroundImage: 'url(' + chevron + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center right 20px',
                    paddingRight: 50,
                },
                '& input[type="submit"]': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    fontFamily: theme.headerFont,
                    fontSize: 16,
                    textAlign: 'center',
                    border: 'none',
                    padding: '4px 20px',
                    backgroundImage: 'url(' + search + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center left calc(50% - 26px)',
                    textIndent: 12,
                },
                '& button': {
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.secondary.contrastText,
                    fontFamily: theme.headerFont,
                    fontSize: 16,
                    textAlign: 'center',
                    border: 'none',
                    padding: '4px 20px',
                }
            }
        }
    },
    searchPage: {
        '& .searching': {
            padding: '24px 0',
        },
        '& .map': {
            position: 'relative'
        },
        '& .info': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '90vw',
            maxWidth: 960,
            backgroundColor: 'white',
            transform: 'translate(-50%, 0)',
            zIndex: 9999999,
            padding: 24,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            [theme.breakpoints.up('sm')]: {
                marginTop: 50,
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'space-between',
                marginTop: 60,
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: 86,
            },
            '& > button': {
                position: 'absolute',
                top: 6,
                right: 6,
                backgroundImage: 'url('+close+')',
                backgroundPosition: 'center',
                fontSize: 0,
                backgroundColor: 'transparent',
                border: 'none',
                padding: 0,
                width: 14,
                height: 14,
            },
            '& .location': {
                flex: '0 0 100%',
                padding: '0 12px',
                '& h3': {
                    fontFamily: 'Overpass',
                    fontSize: 16,
                    letterSpacing: '0.016em',
                    color: theme.palette.primary.main,
                    lineHeight: 1,
                    margin: 0,
                    marginBottom: 5,
                    [theme.breakpoints.up('md')]: {
                        fontSize: 24,
                    }
                },
                '& p': {
                    fontFamily: 'Overpass',
                    opacity: .5,
                    margin: 0,
                    fontSize: 12,
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    [theme.breakpoints.up('md')]: {
                        fontSize: 14,
                    }
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 57.14%',
                    minWidth: '57.14%',
                },
            },
            '& .action': {
                textAlign: 'center',
                flex: '0 0 100%',
                padding: '0 12px',
                '& a': {
                    color: theme.palette.secondary.main,
                    textTransform: 'uppercase',
                    fontFamily: theme.headerFont,
                    fontSize: 14,
                    letterSpacing: '0.016em',
                    backgroundColor: 'white',
                    boxShadow: '0 6px 8px rgba(0,0,0,0.16)',
                    borderRadius: 16,
                    lineHeight: '32px',
                    padding: '0 10px',
                    textAlign: 'center',
                    width: '100%',
                    display: 'block',
                    textDecoration: 'none',
                    transition: 'color .3s ease-in-out, background-color .3s ease-in-out',
                    maxWidth: 240,
                    margin: '12px auto 0',
                    [theme.breakpoints.up('md')]: {
                        margin: '0 0 0 auto'
                    },
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.secondary.main,
                        color: 'white',
                    }
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 14.28%',
                    minWidth: '14.28%',
                },
            }
        }
    },
    searchResults: {
        marginBottom: 40,
        [theme.breakpoints.up('md')]: {
            marginBottom: 100,
        },
        '& .result-item': {
            display: 'flex',
            flexWrap: 'wrap',
            padding: '30px 0',
            borderBottom: '1px solid rgba(0,0,0,0.3)',
            justifyContent: 'center',
            alignItems: 'center',
            '&:nth-of-type(odd)': {
                backgroundColor: 'white',
            },
            '& .location': {
                flex: '0 0 100%',
                padding: '0 12px',
                '& h3': {
                    fontFamily: 'Overpass',
                    fontSize: 24,
                    letterSpacing: '0.016em',
                    color: theme.palette.primary.main,
                    lineHeight: 1,
                    margin: 0,
                    marginBottom: 5,
                },
                '& p': {
                    fontFamily: 'Overpass',
                    opacity: .5,
                    margin: 0,
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 57.14%',
                    minWidth: '57.14%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 50%',
                    minWidth: '50%',
                }
            },
            '& .distance': {
                flex: '0 0 100%',
                padding: '0 12px',
                '& p': {
                    color: theme.palette.secondary.main,
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    fontFamily: 'Overpass',
                    fontWeight: 'bold',
                    '& svg': {
                        width: 18,
                        height: 20,
                        verticalAlign: 'sub',
                    }
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 28.57%',
                    minWidth: '28.57%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 25%',
                    minWidth: '25%',
                }
            },
            '& .action': {
                textAlign: 'center',
                flex: '0 0 100%',
                padding: '0 12px',
                '& a': {
                    color: theme.palette.secondary.main,
                    textTransform: 'uppercase',
                    fontFamily: theme.headerFont,
                    fontSize: 14,
                    letterSpacing: '0.016em',
                    backgroundColor: 'white',
                    boxShadow: '0 6px 8px rgba(0,0,0,0.16)',
                    borderRadius: 16,
                    lineHeight: '32px',
                    padding: '0 10px',
                    textAlign: 'center',
                    width: '100%',
                    display: 'block',
                    textDecoration: 'none',
                    transition: 'color .3s ease-in-out, background-color .3s ease-in-out',
                    maxWidth: 240,
                    margin: '12px auto 0',
                    [theme.breakpoints.up('md')]: {
                        margin: '0 0 0 auto'
                    },
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.secondary.main,
                        color: 'white',
                    }
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 14.28%',
                    minWidth: '14.28%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 12.5%',
                    minWidth: '12.5%',
                }
            }
        }
    },
    letters: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingBottom: 16,
        borderBottom: '1px solid rgba(0,0,0,0.3)',
        '& label, & p': {
            fontSize: 21,
            margin: 5,
            display: 'block',
            fontFamily: theme.headerFont,
        },
        '& label': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&.active': {
                color: theme.palette.secondary.main,
                textDecoration: 'underline',
            },
            '&.disabled': {
                cursor: 'initial',
                color: 'grey',
            }
        },
        '& span': {
            position: 'relative'
        },
        '& input': {
            position: 'absolute',
            opacity: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',

            '&:disabled': {
                cursor: 'default'
            }
        }
    },
});