import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import scrollTo from "gatsby-plugin-smoothscroll";
import styles from "./Pagination.styles";
import { setPage } from "../../app/actions";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  page: state.ShopSettings.page,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setPage(page)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function Pagination({ products, page, setPage, num = 9, scrollTarget }) {
  const classes = useStyles();
  const totalPages = [...Array(Math.ceil(products.length / num))].map((_) =>
    products.slice(0, num)
  );

  if (totalPages.length < 2) return null;

  function paginationHandler(index) {
    setPage(index + 1);
    setTimeout(() => scrollTo(scrollTarget), 100);
  }

  if (totalPages.length > 10) {
    return (
      <ul className={classes.pagination}>
        {page < 4 && (
          <>
            <li>
              <a //eslint-disable-line
                className={`${1 === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(0)}
              >
                1
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                className={`${2 === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(1)}
              >
                2
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                className={`${3 === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(2)}
              >
                3
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(3)}
              >
                4
              </a>
            </li>
            ...
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(totalPages.length - 2)}
              >
                {totalPages.length - 1}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(totalPages.length - 1)}
              >
                {totalPages.length}
              </a>
            </li>
          </>
        )}

        {page >= 4 && page < totalPages.length - 2 && (
          <>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(0)}
              >
                1
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(1)}
              >
                2
              </a>
            </li>
            ...
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(page - 2)}
              >
                {page - 1}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                className={`active`}
                onClick={(e) => paginationHandler(page - 1)}
              >
                {page}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(page)}
              >
                {page + 1}
              </a>
            </li>
            ...
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(totalPages.length - 2)}
              >
                {totalPages.length - 1}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(totalPages.length - 1)}
              >
                {totalPages.length}
              </a>
            </li>
          </>
        )}

        {page > totalPages.length - 3 && (
          <>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(0)}
              >
                1
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                onClick={(e) => paginationHandler(1)}
              >
                2
              </a>
            </li>
            ...
            <li>
              <a //eslint-disable-line
                className={`${totalPages.length - 3 === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(totalPages.length - 4)}
              >
                {totalPages.length - 3}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                className={`${totalPages.length - 2 === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(totalPages.length - 3)}
              >
                {totalPages.length - 2}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                className={`${totalPages.length - 1 === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(totalPages.length - 2)}
              >
                {totalPages.length - 1}
              </a>
            </li>
            <li>
              <a //eslint-disable-line
                className={`${totalPages.length === page ? "active" : ""}`}
                onClick={(e) => paginationHandler(totalPages.length - 1)}
              >
                {totalPages.length}
              </a>
            </li>
          </>
        )}
      </ul>
    );
  }

  return (
    <ul className={classes.pagination}>
      {totalPages.map((p, index) => {
        return (
          <li key={index}>
            <a //eslint-disable-line
              className={`${index + 1 === page ? "active" : ""}`}
              onClick={(e) => paginationHandler(index)}
            >
              {index + 1}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default connector(Pagination);
