export default (theme) => ({
  pagination: {
    display: "flex",
    marginTop: 40,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      marginTop: 100,
    },
    "& li": {
      display: "block",
      "& a": {
        fontSize: 18,
        fontFamily: theme.headerFont,
        color: "black",
        cursor: "pointer",
        textDecoration: "none",
        display: "block",
        textAlign: "center",
        lineHeight: "56px",
        width: 56,
        margin: "0 12px",
        "&.active": {
          backgroundColor: theme.palette.secondary.main,
          color: "white",
        },
      },
    },
  },
});
