import React from "react";
import { graphql } from "gatsby";

import SearchPage from "../components/StockistSearch";
import Layout from "../components/layout";
import Breadcrumb from "../components/Breadcrumb";

export default function Search({ data, location }) {
  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/distributors/",
      }}
      path={"/distributors/"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <Breadcrumb
        type="page"
        current={{
          title: "Distributors",
          slug: "distributor",
          uri: "/distributors",
        }}
      />
      <SearchPage
        locations={data.allWpLocation}
        factors={data.allWpStockist}
        prefill={location.state}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    allWpLocation(limit: 600, sort: { fields: title }) {
      nodes {
        title
        uri
        LocationInformation {
          address1
          address2
          address3
          address4
          city
          companyInfo
          country
          email
          fax
          fieldGroupName
          latitude
          longitude
          memberId
          openingTimes
          postcode
          telephone
          url
          stockist {
            ... on WpStockist {
              id
              slug
            }
          }
        }
      }
    }
    allWpStockist {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
