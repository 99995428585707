import React from 'react';
import styles from './styles';
import { makeStyles } from '@material-ui/styles';

import { CoreParagraphBlock } from "../../blocks/CoreParagraphBlock";
import { CoreButtonBlock } from "../../blocks/CoreButtonBlock";

const useStyles = makeStyles(theme => styles(theme));

export default function ProductSignUp() {
    const classes = useStyles();

    return <div className={`product-signup mb-0 ${classes.calloutCta} ${classes.calloutCtaTheme}`}>
        <CoreParagraphBlock
            attributes={{
                align: "",
                backgroundColor: "",
                className: "",
                content: "Sign up to our newsletter and you'll receive the latest in industry news, promotions, and events.",
                direction: "",
                textColor: "",
                __typename: "WpCoreParagraphBlockAttributes",
            }}
            innerBlocks={[]}
        />
        <CoreButtonBlock
            attributes={{
                align: "right",
                backgroundColor: "foreground_primary",
                borderRadius: 4,
                className: "",
                gradient: "",
                linkTarget: "",
                placeholder: "",
                rel: "",
                text: "Sign up today",
                textColor: "secondary",
                title: "",
                url: "/sign-up/",
                __typename: "WpCoreButtonBlockAttributes",
            }}
            innerBlocks={[]}
        />
    </div>
}